import React, { useState } from "react";
import { Navbar, Container, Offcanvas } from "react-bootstrap";
import HeaderLogo from "../HeaderLogo/HeaderLogo";
import HeaderMenu from "../HeaderMenu/HeaderMenu";
import BurgerMenu from "../BurgerMenu/BurgerMenu";
import BurgerMenuList from "../BurgerMenuList/BurgerMenuList";
import useHasScrolled from "../../hooks/useHasScrolled";
import './assets/styles/_index.scss';

const Header = (props) => {

    // Scroll
    const scrolled = useHasScrolled()
    // Scroll

    // Burger menu
    const [showBurger, setShowBurger] = useState(false);
    const handleCloseBurger = () => setShowBurger(false);
    const handleShowBurger = () => setShowBurger(true); 
    // Burger menu

    return (
        <>
            <header className={`header d-flex align-items-center ${scrolled ? "header-scrolled" : ""}`}>
                <Navbar bg="" expand="lg" fixed="top">
                    <Container fluid>
                        <HeaderLogo />

                        <HeaderMenu />

                        <BurgerMenu handleShowBurger={handleShowBurger} />
                    </Container>
                </Navbar>            
            </header>
            <Offcanvas show={showBurger} onHide={handleCloseBurger} placement="end" className="burger-menu-wrapper-offcanvas">
                <Offcanvas.Header closeButton></Offcanvas.Header>
                <Offcanvas.Body>
                    <BurgerMenuList />
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default Header