import React from "react";
import { graphql } from 'gatsby'
import loadable from "@loadable/component";
import Layout from "../components/layout";
import SEO from "../components/seo"
import ReviewsImg from "../images/reviews_img.png";

const Banner = loadable(() => import("../components/Banner/Banner"));
const InnerIntro = loadable(() => import("../components/InnerIntro/InnerIntro"));
const StatsModule = loadable(() => import("../components/StatsModule/StatsModule"));
const CareerPositions = loadable(() => import("../components/CareerPositions/CareerPositions"));
const ValuationModule = loadable(() => import("../components/ValuationModule/ValuationModule"));
const ReviewsModule = loadable(() => import("../components/ReviewsModule/ReviewsModule"));

const CareerLanding = ({ data }, props) => {
    const PageData = data?.strapiPage
    const Jobs = data?.allStrapiCareer.edges
    return (
        <Layout popularSearch={PageData?.select_popular_search?.title}>
            {PageData.banner && <Banner title={PageData.banner?.banner_title} {...PageData.banner} id={PageData.strapi_id} imagetransforms={PageData.imagetransforms} tag="career-landing"/> }

            {PageData?.Add_Page_Modules?.length > 0 && PageData.Add_Page_Modules?.map((module, index) => {
                return (
                    <>
                        {module.strapi_component === "page-modules.text-module" && module.layout === "two_columns" && <InnerIntro {...module} /> }

                        {module.strapi_component === "page-modules.image-and-content" && module.layout === "fullwidth" && <ValuationModule {...module} id={PageData.strapi_id} imagetransforms={PageData.imagetransforms} />}

                        {module.strapi_component === "page-modules.global-module" && module.select_module === "google_reviews_slider" && <ReviewsModule reviewsImg={ReviewsImg} />}

                        {module.strapi_component === "page-modules.global-module" && module.select_module === "careers" && <CareerPositions positionDetail={Jobs} {...module}/>}

                        {module.strapi_component === "page-modules.stats-module" && <StatsModule {...module} />}

                    </>
                )
            })}
        </Layout>
    )
}

export const Head = ({ data }) => {
    const PageData = data?.strapiPage
    return (
        <SEO title={PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title} description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title} />
    )
}

export default CareerLanding


export const query = graphql`
  query ($page_id: String) {
      strapiPage(id: {eq: $page_id}) {
          ...PageFragment
          Add_Page_Modules {
              ... on STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE {
                ...GlobalModuleFragment
              }
              ... on STRAPI__COMPONENT_PAGE_MODULES_PLAIN_CONTENT {
                ...PlainContentFragment
              }
              ... on STRAPI__COMPONENT_PAGE_MODULES_TEXT_MODULE {
                ...TextModuleFragment
              }
              ... on STRAPI__COMPONENT_PAGE_MODULES_IMAGE_AND_CONTENT {
                ...ImageAndContentFragment
              }
              ... on STRAPI__COMPONENT_PAGE_MODULES_STATS_MODULE {
                ...StatsModuleFragment
              }
          }
      }
      
      allStrapiCareer(filter: {publish: {eq: true}}) {
          edges {
              node {
                ...CareerFragment
              }
          }
      }
    }
  `