/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/
 */

import * as React from "react";
import Header from "./Header/Header";
import loadable from "@loadable/component";
import "animate.css/animate.min.css";
import "../styles/main.scss";
const Footer = loadable(() => import("./Footer/Footer"));

const Layout = ({ children, footerContact, popularSearch }) => {

  return (
    <>
      <Header />
        <main>{children}</main>
      <Footer popularSearch={popularSearch} footerContact={footerContact}/>
    </>
  )
}

export default Layout